import React, { useEffect, useState } from "react";

import { Box, IconButton } from "@mui/material";
import {
  AddShoppingCartOutlined,
  AttachFile,
  Poll,
  Event,
  LiveTv,
} from "@mui/icons-material";

import NewNote from "./NewNote";
import NewImage from "./NewImage";
import NewVideo from "./NewVideo";
import NewAudio from "./NewAudio";
import NewExperience from "./NewExperience";
import NewPoll from "./NewPoll";
import NewMerch from "./NewMerch";
import NewCarousel from "./NewCarousel";
// import NewStream from "./NewStream";

export default function Post() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [contentType, setContentType] = useState("note");

  const componentMap = {
    audio: <NewAudio file={file} setFile={setFile} />,
    image: <NewImage file={file} />,
    carousel: <NewCarousel files={files} />,
    video: <NewVideo file={file} />,
    experience: <NewExperience />,
    poll: <NewPoll />,
    merch: <NewMerch />,
    // stream: <NewStream />,
  };

  const setFileType = (file) => {
    if (file.type.includes("audio")) {
      setContentType("audio");
    } else if (file.type.includes("image") && files.length > 1) {
      setContentType("carousel");
    } else if (file.type.includes("image")) {
      setContentType("image");
    } else if (file.type.includes("video")) {
      setContentType("video");
    }
  };

  useEffect(() => {
    if (file) {
      setFileType(file);
    }
  }, [file]);

  return (
    <Box sx={{minHeight: '80vh'}}>
      {contentType === "note" && (
        <>
          <NewNote />
          <ButtonRow
            setFile={setFile}
            setContentType={setContentType}
            setFiles={setFiles}
          />
        </>
      )}

      {componentMap[contentType]}
    </Box>
  );
}

function ButtonRow({ setFile, setContentType, setFiles }) {
  const buttonStyle = {
    border: "1px solid #9A5AEF",
    marginRight: "20px",
  };

  const iconMap = {
    poll: <Poll />,
    merch: <AddShoppingCartOutlined />,
    experience: <Event />,
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start", ml: 2, mt: 2 }}>
      <AttachFileButton setFile={setFile} setFiles={setFiles} />
      {/* <IconButton
        size="large"
        variant="outlined"
        style={{
          border: "1px solid #04FFFF",
          marginRight: "20px",
        }}
        onClick={() => setContentType("stream")}
      >
        <LiveTv />
      </IconButton> */}
      {Object.keys(iconMap).map((key) => (
        <Box key={key}>
          <IconButton
            size="large"
            variant="outlined"
            style={buttonStyle}
            onClick={() => setContentType(key)}
          >
            {iconMap[key]}
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}

function AttachFileButton({ setFile, setFiles }) {

  const handleFileChange = (e) => {
    setFiles(e.target.files);
    setFile(e.target.files[0]);
  }

  return (
    <Box>
      <input
        accept="audio/*;image/*;video/*"
        style={{ display: "none" }}
        id="icon-button-file"
        type="file"
        multiple={true}
        // onChange={(e) => setFile(e.target.files[0])}
        onChange={handleFileChange}
      />
      <label htmlFor="icon-button-file">
        <IconButton
          size="large"
          variant="outlined"
          style={{ background: "#9A5AEF", marginRight: "20px" }}
          component="span"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("icon-button-file").click();
          }}
        >
          <AttachFile />
        </IconButton>
      </label>
    </Box>
  );
}
