import React from "react";

import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CommentsContainer from "./CommentsContainer";

import ActionDrawer from "../ActionDrawer";


export default function CommentsDrawer({ content, open, setOpen }) {
  

  return (
    <>
      {content && (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          style={{ height: "100%" }}
          PaperProps={{ style: { background: "#47596E" } }}
        >
          <AppBar
            position="sticky"
            elevation={0}
            style={{
              background: "#47596E",
              // borderBottom: "1px solid lightgray",
            }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <IconButton
                onClick={() => setOpen(false)}
                style={{ margin: "0 0 0 -10px" }}
                color="inherit"
              >
                <ExpandMoreIcon style={{ fontSize: "30px" }} />
              </IconButton>

              <ActionDrawer content={content} />
            </Toolbar>
          </AppBar>
          <Container
            maxWidth="sm"
            sx={{ height: "80vh" }}
          >
            <CommentsContainer content={content} />
          </Container>
        </Drawer>
      )}
    </>
  );
}

