import axios from "axios";
import React from "react";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import CommentsContainer from "../Comments/CommentsContainer";

import CaptionContainer from "../../fragments/CaptionContainer";

import ExperienceCard from "../ExperiencesCard";

import ActionDrawer from "../ActionDrawer";

import ReactPlayer from "react-player";
import { API_BASE, currentUser } from "../../App";
// import { ExperienceContentCard } from "../ContentCards";

import { fromNow } from "../../utils";

export default function ActiveContentDrawer({ content, open, setOpen }) {
  const handleBuyNow = async (content) => {
    const result = confirm(
      "You will now be redirected to the artist's store. Continue?"
    );

    // log event "click_buy_now"
    const endpoint = API_BASE + "/event";
    const response = axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: "click_buy_now",
    });

    window.location.href = content.meta.link;
  };

  const handleClose = () => {
    console.log(open);
    console.log("closing");
    setOpen(false);
  };

  return (
    <>
      {content && (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            style: { background: "#47596E", borderRadius: "20px 20px 0 0" },
          }}
        >
          <AppBar
            position="sticky"
            elevation={0}
            style={{
              background: "#47596E",
            }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <IconButton
                onClick={handleClose}
                // onClick={() => console.log("clicked")}
                style={{ margin: "0 0 0 -10px" }}
                color="inherit"
              >
                <ExpandMoreIcon style={{ fontSize: "30px" }} />
              </IconButton>

              <ActionDrawer content={content} />
            </Toolbar>
            <Box>
              <Container
                maxWidth="sm"
                sx={{ pt: 1, pb: 4, borderBottom: "1px solid #2E3A4D" }}
              >
                {content.type === "thread" ? (
                  <Typography
                    textAlign="center"
                    sx={{ fontWeight: 800, fontSize: "1.2rem" }}
                  >
                    {content.meta.title}
                  </Typography>
                ) : (
                  <CaptionContainer content={content} />
                )}
              </Container>
            </Box>
          </AppBar>
          <Box sx={{ height: "60vh", px: 1 }}>
            <Container maxWidth="sm">
              {content.type === "thread" && (
                <Box sx={{ mt: 2 }}>
                  <CaptionContainer content={content} />
                </Box>
              )}
              {content.type === "video" && (
                <Card style={{ borderRadius: "20px" }}>
                  <ReactPlayer
                    url={content.meta["url"]}
                    playing
                    controls
                    width="100%"
                    height="100%"
                  />
                </Card>
              )}

              {content.type == "merch" && (
                <Box style={{ borderRadius: "20px" }} sx={{ mt: 2 }}>
                  <CardMedia
                    component="img"
                    src={content.meta["cover"]}
                    style={{ borderRadius: "10px" }}
                  />

                  <Typography sx={{ mt: 1 }}>${content.meta.price}</Typography>
                  <Button
                    fullWidth
                    startIcon={<ShoppingCartIcon />}
                    variant="outlined"
                    color="secondary"
                    sx={{ my: 2 }}
                    onClick={() => handleBuyNow(content)}
                    style={{ borderRadius: "20px" }}
                  >
                    BUY
                  </Button>
                </Box>
              )}

              {/* {content.meta.tags && (
                <Typography
                  variant="caption"
                  style={{ color: "darkgray" }}
                  sx={{ mb: 2 }}
                >
                  @ {content.meta.tags.join(", ")}
                </Typography>
              )} */}

              <CommentsContainer content={content} />
            </Container>
          </Box>
        </Drawer>
      )}
    </>
  );
}
