import React from "react";

import { AppBar, Box, Toolbar, Typography } from "@mui/material";

import { Verified } from "@mui/icons-material";

export default function PageAppBar() {
  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ background: "rgba(36,36,36,.9)" }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            opacity: 0.9
          }}
          sx={{ my: 2 }}
        >
          <Verified color="secondary" sx={{ mr: 1 }} />
          <Typography variant="h6">Verified Artist Setup</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
