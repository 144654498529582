import React, { useState, createContext, useEffect, useRef } from "react";
import axios from "axios";
import { API_BASE, UI_STAGE, currentUser, artistKey } from "../App";
import { getContentArtwork } from "../utils";

export const MusicContext = createContext();

export const MusicProvider = ({ children }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [music, setMusic] = useState([]);
  const [shuffledMusic, setShuffledMusic] = useState([]);
  const [shuffleMode, setShuffleMode] = useState(false);
  const [repeatMode, setRepeatMode] = useState(false); // Now a boolean

  const [musicCount, setMusicCount] = useState(0);
  const [musicLoading, setMusicLoading] = useState(true);

  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(null);
  const isLimited = localStorage.getItem("limited") === "true";

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ item, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ item }) => item);
  };

  const toggleShuffleMode = () => {
    setShuffleMode((prevMode) => {
      if (!prevMode) {
        setShuffledMusic(shuffleArray(music)); // Shuffle music when enabling shuffle
      }
      return !prevMode;
    });
  };

  const getCurrentPlaylist = () => (shuffleMode ? shuffledMusic : music);


  const toggleRepeatMode = () => {
    setRepeatMode(!repeatMode); // Toggle between repeating one song and looping the playlist
  };

  const handlePlay = async (content) => {
    if (isLimited) {
      alert(
        "Music playback is not available on your Pocketbook Edition subscription."
      );
      return;
    }

    if (currentSong?.key !== content.key) {
      setCurrentSong(content);
      setCurrentTime(0);
      logEvent(content, "play");
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    setIsPlaying(true);
    audioRef.current.play();
  };

  const handlePause = async () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const handleNext = async () => {
    logEvent(currentSong, "next");
  
    const playlist = getCurrentPlaylist();
    const index = playlist.findIndex((song) => song.key === currentSong.key);
  
    if (repeatMode) {
      // If repeat mode is true, replay the current song
      handlePlay(currentSong);
    } else {
      // Move to the next song, and loop back to the first song if at the end
      const nextIndex = (index + 1) % playlist.length;
      const nextSong = playlist[nextIndex];
  
      handlePlay(nextSong);
    }
  };

  const handlePrevious = async () => {
    if (currentTime > 5) {
      audioRef.current.currentTime = 0;
      return;
    }

    const playlist = getCurrentPlaylist();
    const index = playlist.findIndex((song) => song.key === currentSong.key);
    const previousIndex = (index - 1 + playlist.length) % playlist.length;
    const previousSong = playlist[previousIndex];
    handlePlay(previousSong);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const time = Math.floor(audioRef.current.currentTime);
      setCurrentTime(time);

      if (time >= Math.floor(audioRef.current.duration)) {
        if (repeatMode) {
          // If repeatMode is true, replay the same song
          audioRef.current.currentTime = 0;
          handlePlay(currentSong);
        } else {
          // Otherwise, move to the next song or handle playlist looping
          handleNext();
        }
      }
    }
  };

  const logEvent = async (content, eventType) => {
    await axios.post(API_BASE + "/event", {
      user_key: currentUser.key,
      content_key: content.key,
      type: eventType,
      data: {
        current_time: currentTime,
      },
    });
  };

  const handleClearSong = () => {
    setCurrentSong(null);
    audioRef.current.pause();
  };

  const handleTimeSliderChange = (e, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  useEffect(() => {
    const getUserContentUrl = async (content) => {
      // exit is is limited
      if (isLimited) {
        return {
          ...content,
          userUrl: null,
          error: true,
        };
      }

      try {
        const CLOUD_API_BASE = `https://us-central1-notebook-fm-${UI_STAGE}.cloudfunctions.net`;
        // const CLOUD_API_BASE = `https://us-central1-notebook-fm-prod.cloudfunctions.net`;
        const endpoint = `${CLOUD_API_BASE}/get-user-content-url`;

        const response = await axios.post(endpoint, {
          content_key: content.key,
          user_key: currentUser.key,
        });

        return {
          ...content,
          userUrl: response.data.public_url,
        };
      } catch (error) {
        console.error("Error fetching user content URL:", error);
        return {
          ...content,
          userUrl: null,
          error: true,
        };
      }
    };

    const fetchMusic = async () => {
      try {
        const endpoint = `${API_BASE}/music`;

        const response = await axios.get(endpoint, {
          params: {
            user_key: currentUser.key,
            artist_key: artistKey,
          },
        });

        // Sort the music by created date in descending order
        const rawMusic = response.data.content.sort(
          (a, b) => b.created - a.created
        );

        // setMusic(rawMusic);
        setMusicCount(rawMusic.length);

        const resolvedMusic = await Promise.all(
          rawMusic.map(getUserContentUrl)
        );

        setMusic(resolvedMusic);
        
      } catch (error) {
        console.error("Error fetching music:", error);
        setMusic([]);
        
      } finally {
        setMusicLoading(false);
      }
    };

    if (currentUser && artistKey && subscribed) {
      fetchMusic();
    }
  }, [subscribed]);

  useEffect(() => {
    if ("mediaSession" in navigator && currentSong) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: currentSong?.meta["title"],
        artist: currentSong?.owner.name,
        album: "Notebook",
        artwork: [
          {
            src: getContentArtwork(currentSong),
          },
        ],
      });

      navigator.mediaSession.setActionHandler("play", () => {
        audioRef.current.play();
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        audioRef.current.pause();
      });
      navigator.mediaSession.setActionHandler("previoustrack", handlePrevious);
      navigator.mediaSession.setActionHandler("nexttrack", handleNext);
    }
  }, [currentSong]);

  return (
    <MusicContext.Provider
      value={{
        music,
        musicLoading,
        musicCount,
        currentSong,
        isPlaying,
        setIsPlaying,
        handlePause,
        handlePlay,
        handleNext,
        handlePrevious,
        currentTime,
        duration,
        handleClearSong,
        handleTimeSliderChange,
        subscribed,
        setSubscribed,
        repeatMode,
        toggleRepeatMode,
        shuffleMode,
        toggleShuffleMode,
      }}
    >
      <audio
        ref={audioRef}
        src={currentSong?.userUrl}
        preload="metadata"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(audioRef.current.duration)}
      />

      {children}
    </MusicContext.Provider>
  );
};