import React, { useState } from "react";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import { API_BASE, currentUser } from "../../App";

export default function PollCard({ content, setVoted }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleVote = async (option) => {
    setLoading(true);
    setSelected(option);
    const endpoint = API_BASE + "/event";
    const data = {
      user_key: currentUser.key,
      content_key: content.key,
      type: "vote",
      data: { text: option },
    };
    const response = await axios.post(endpoint, data);
    setVoted(true);
    
  };

  return (
    <Card style={{ borderRadius: "10px" }}>
      <CardContent>
        <Typography
          variant="body1"
          sx={{ mb: 2 }}
          style={{ fontWeight: "bold" }}
        >
          Q: {content.meta.question}
        </Typography>
        {content.meta.options
          .filter((option) => option)
          .map((option, index) => (
            <Box
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant={selected === option ? "contained" : "outlined"}
                color="secondary"
                fullWidth
                style={{ borderRadius: "30px", margin: "10px 0" }}
                onClick={() => handleVote(option)}
                disabled={loading}
              >
                {option}
              </Button>
            </Box>
          ))}
      </CardContent>
    </Card>
  );
}
