import axios from "axios";
import { useState } from "react";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { Close, ContentCopy, Instagram, Verified } from "@mui/icons-material";

import SpotifyIcon from "../../../fragments/SpotifyIcon";

import { API_BASE, authHeaders, currentUser } from "../../../App";
import { setCurrentUser } from "../../../utils";

import {
  ProfileImageContainer,
  NotebookCoverContainer,
  MusicCoverContainer,
  WelcomeVideoContainer,
} from "./EditMedia";
import Subscriptions from "./Subscriptions";

export default function ArtistProfile() {
  const [name, setName] = useState(currentUser["name"]);
  const [profile, setProfile] = useState(currentUser.meta["profile"] || "");
  const [bio, setBio] = useState(currentUser.meta["bio"] || "");

  const [rate, setRate] = useState(currentUser.meta.rate || 4);
  const [limited, setLimited] = useState(currentUser.meta.limited || rate / 2);
  const [pocketbook, setPocketbook] = useState(
    currentUser.meta.pocketbook || "off"
  );

  const [cover, setCover] = useState(currentUser.meta.cover);
  const [artwork, setArtwork] = useState(currentUser.meta.artwork);
  const [welcome, setWelcome] = useState(currentUser.meta.welcome);
  const [instagram, setInstagram] = useState(currentUser.meta.instagram || "");
  const [spotify, setSpotify] = useState(currentUser.meta.spotify || "");

  // loading states
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const userData = {
        name: name,
        key: currentUser.key,
        meta: {
          profile: profile,
          spotify: spotify,
          instagram: instagram,
          bio: bio,
          cover: cover,
          artwork: artwork,
          welcome: welcome,
          rate: rate,
          limited: limited,
          pocketbook: pocketbook,
        },
      };

      const endpoint = API_BASE + "/user/update";
      const response = await axios.post(endpoint, userData, authHeaders);
      setCurrentUser(response.data);

      if (currentUser.role === "artist") {
        window.location.href = "/" + currentUser.key;
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      // Display error message
      alert(`Error updating profile: ${error.message}`);
      console.error("Update failed:", error);
    } finally {
      setLoading(false);
    }
  };
  // State to control the visibility of the limited slider



  return (
    <Box sx={{ pb: 10 }}>
      <PageAppBar
        loading={loading}
        handleUpdate={handleUpdate}
        name={name}
        uploading={uploading}
      />

      <Box sx={{ px: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, pt: 2 }}>
          <ProfileImageContainer
            profile={profile}
            setProfile={setProfile}
            setUploading={setUploading}
          />

          <TextField
            label="Name"
            variant="outlined"
            value={name}
            required
            fullWidth
            onChange={(e) => setName(e.target.value)}
            sx={{ my: 2 }}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />
        </Box>

        <CopyLink />

        {/* Rate Sliders */}
        <Subscriptions
          rate={rate}
          setRate={setRate}
          limited={limited}
          setLimited={setLimited}
          pocketbook={pocketbook}
          setPocketbook={setPocketbook}
        />

        <Box sx={{ my: 6 }}>
          <Typography variant="h6" sx={{ my: 4 }}>
            Notebook Display
          </Typography>
          <Box
            sx={{
              display: "flex",
              overflowX: "scroll",
              scrollbarWidth: "none" /* Firefox */,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              gap: 2,
            }}
          >
            <NotebookCoverContainer
              cover={cover}
              setCover={setCover}
              setUploading={setUploading}
            />
            <MusicCoverContainer
              artwork={artwork}
              setArtwork={setArtwork}
              setUploading={setUploading}
            />
            <WelcomeVideoContainer
              welcome={welcome}
              setWelcome={setWelcome}
              setUploading={setUploading}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            sx={{ my: 2 }}
          >
            <TextField
              label={<SpotifyIcon />}
              placeholder="https://open.spotify.com/artist/YOUR_ID"
              value={spotify}
              onChange={(e) => setSpotify(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
            <TextField
              label={<Instagram color="white" />}
              placeholder="https://www.instagram.com/YOUR_USERNAME"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function CopyLink() {
  const publicUrl = `notebook.fm/${currentUser.key}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(publicUrl);
    alert("Link copied to clipboard.");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        my: 4, 
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleCopy}>
        <ContentCopy color="primary" />
      </IconButton>
      <Typography variant="body1">{publicUrl}</Typography>
      {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'center', width: '100%' }}>
        <Typography variant="body1">{publicUrl}</Typography>
      </Box> */}
    </Box>
  );
}

function PageAppBar({ loading, handleUpdate, name, uploading }) {
  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        {uploading && <LinearProgress color="secondary" />}
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "67px" }}>
            <IconButton onClick={() => (window.location.href = "/")}>
              <Close />
            </IconButton>
          </Box>

          <Box
            style={{ display: "flex", alignItems: "center" }}
            // onClick={() => gotoArtist(currentUser.key)}
          >
            <Verified
              color={currentUser.verified ? "secondary" : "disabled"}
              sx={{ mr: 1 }}
            />
            <Typography variant="h6">{currentUser.key}</Typography>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpdate}
            disabled={loading || uploading || name === ""}
            style={{ borderRadius: "20px" }}
          >
            SAVE
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
