import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { API_BASE } from "../../App";
import { Skeleton } from "@mui/material";

export default function PollResultCard({ content }) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/poll_results";  // Assuming you have an endpoint to get poll results
      const response = await axios.get(endpoint, {
        params: {
          content_key: content.key,
        },
      });
      setResults(response.data.results);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching poll results:", error);
      setLoading(false);
    }
  };

  const calculatePercentage = (count, total) => {
    return total === 0 ? 0 : ((count / total) * 100).toFixed(2);
  };

  const totalVotes = results.reduce((sum, option) => sum + option.count, 0);

  return (
    <Card style={{ borderRadius: "10px" }}>
      <CardContent>
        <Typography variant="body1" sx={{ mb: 2 }} style={{ fontWeight: "bold" }}>
          Q: {content.meta.question}
        </Typography>
        {loading ? (
          <Box>

            <Skeleton variant="text" width={120} sx={{mt: 1}}/>
            <Skeleton variant="text" />
            <Skeleton variant="text" width={220}/>

            <Skeleton variant="text" width={120} sx={{mt: 1}}/>
            <Skeleton variant="text" />
            <Skeleton variant="text" width={220}/>

            <Skeleton variant="text" width={120} sx={{mt: 1}}/>
            <Skeleton variant="text" />
            
            <Skeleton variant="text" width={220}/>
          </Box>
        ) : (
          content.meta.options
            .filter((option) => option)
            .map((option, index) => {
              const result = results.find((res) => res.text === option) || { count: 0 };
              const percentage = calculatePercentage(result.count, totalVotes);
              return (
                <Box key={index} sx={{ my: 2 }}>
                  <Typography variant="body2">{option}</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={parseFloat(percentage)}
                    sx={{ height: 10, borderRadius: 5, my: 0.5 }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {result.count} votes ({parseFloat(percentage).toFixed(1)}%)
                  </Typography>
                </Box>
              );
            })
        )}
        {!loading && totalVotes === 0 && (
          <Typography variant="body1">No votes yet.</Typography>
        )}
      </CardContent>
    </Card>
  );
}