import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  AppBar,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";

import { Replay, Verified } from "@mui/icons-material";
import { API_BASE, artistKey, currentUser, authHeaders } from "../App";
import { fromNow, setCurrentUser } from "../utils";

export default function PublishAppBar({ artist }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const KEYS = [
    "juliamichaels",
    "julianbulian",
    "skidder",
    "022b1678-ba11-473e-a77c-85566b006bbe",
  ];

  const approveArtist = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/artist/approve";
    const response = await axios.post(
      endpoint,
      { artist_key: artist.key, user_key: currentUser.key},
      { headers: authHeaders }
    );
    console.log("Approve Artist Response", response.data);
    setCurrentUser(response.data.user);
    setLoading(false);
    setOpen(false)
  };

  useEffect(() => {
    // console.log("artist", artist);
    if (
      artist &&
      artist.public &&
      !artist.verified &&
      KEYS.includes(currentUser.key)
    ) {
      setShow(true);
    }
  }, [artist]);

  return (
    <>
      {show && (
        <>
          <AppBar
            position="sticky"
            elevation={0}
            style={{ background: "rgba(36,36,36,.9)" }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
              }}
            >
              {/* <Box sx={{ width: "65px", px: 2 }}>
                <Typography variant='body' color='inherit'>
                  {fromNow(artist?.meta.publish)}
                </Typography>
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Verified
                  color="secondary"
                  sx={{ mr: 1 }}
                  style={{ fontSize: "1.2rem" }}
                  onClick={() => setOpen(true)}
                />
                <Typography variant="body" color="inherit">
                  In Review
                </Typography>
              </Box>

              <Button
                variant="text"
                color="secondary"
                onClick={() => alert("PH Required")}
              >
                Approve
              </Button>
            </Toolbar>
          </AppBar>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography variant="h6">Your account is in review</Typography>

              <Typography sx={{ my: 2 }}>
                Please reach artists@notebook.fm with any questions
              </Typography>

              <Typography sx={{ my: 2 }}>- Julia</Typography>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button color="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
