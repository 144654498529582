import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import NotesIcon from "../assets/notes.svg";

import NotebookIcon from "./NotebookIcon";

import { artistKey } from "../App";

export default function ArtistLogo({ }) {
  return (
    <Box
      className="artist-logo"
      style={{
        // width: 200,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {/* <img src={NotesIcon} style={{ height: 20 }}  />
      NotebookIcon */}
      <NotebookIcon size={24}/>

      <Typography
        style={{
          fontFamily: "'Montserrat Alternates', sans-serif",
          fontWeight: 700,
          fontSize: "1.4rem",
          marginBottom: '0px',
          marginLeft: '12px'
        }}
        // sx={{ ml: 2}}
      >
        {artistKey || "notebook"}
        
      </Typography>
    </Box>
  );
}
