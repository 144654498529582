import axios from "axios";
import React, { useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import {
  ArrowUpward,
  Cancel,
  Verified,
  Star,
  StarBorder,
  StarBorderOutlined,
} from "@mui/icons-material";

import UserDrawer from "../UserDrawer";

import { API_BASE, currentUser } from "../../App";
import { fromNow, formatSeconds } from "../../utils";

export default function CommentCard({ event, fetchComments, content }) {
  const avatarSize = event.parent_id ? 28 : 34;
  const [favorite, setFavorite] = useState(event.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(event.favorite_count);

  const [showUser, setShowUser] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      parent_id: event.id,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box id={event.id} style={{ display: "flex", alignItems: "flex-start" }}>
      <Avatar
        src={event.user.meta.profile}
        sx={{ width: avatarSize, height: avatarSize, mr: 1 }}
        onClick={() => setShowUser(true)}
      />
      <Box id="post-content-container" style={{ width: "100%" }}>
        <Box
          id="post-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: "-5px",
          }}
        >
          <Box
            id="user-detail"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="body"
              style={{ marginRight: "3px", fontWeight: "bold" }}
            >
              {event.user.name}
            </Typography>

            {event.user.role == "artist" && (
              <Verified
                color={event.user.verified ? "secondary" : "disabled"}
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
            )}

            {event.user.role == "user" && event.user.verified && (
              <Verified
                color="primary"
                style={{ fontSize: "15px", marginRight: "5px" }}
              />
            )}

            <Typography
              variant="caption"
              style={{ margin: "3px 3px 0 0", color: "lightgray" }}
            >
              {fromNow(event.created)}
            </Typography>

            {event.data.current_time && (
              <Typography
                variant="caption"
                style={{ margin: "3px 3px 0 3px", color: "lightgray" }}
              >
                (at {formatSeconds(event.data.current_time)})
              </Typography>
            )}

            {event.artist_favorite && (
              <Box>
                <Typography
                  variant="caption"
                  style={{
                    color: "lightgray",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  •
                  <Star
                    color="secondary"
                    style={{ fontSize: "15px", margin: "0 2px" }}
                  />
                  by artist
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box id="post-content" style={{ marginTop: "-5px" }}>
          {/* needs to wrap long comment */}
          <Box style={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }}>
            <Typography variant="body" style={{ marginRight: "5px" }}>
              {event.data.text}
            </Typography>
          </Box>
        </Box>
        <PostActions
          event={event}
          fetchComments={fetchComments}
          content={content}
        />
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <IconButton size="small" onClick={handleFavorite} sx={{ p: 0 }}>
          {favorite ? (
            <Star color="secondary" />
          ) : (
            <StarBorder style={{ color: "lightgray" }} />
          )}
        </IconButton>
        <Typography
          variant="caption"
          style={{ color: "lightgray", marginTop: "2px" }}
        >
          {favoriteCount > 0 ? favoriteCount : ""}
        </Typography>
        {/* if artist_favorite */}
      </Box>
      <UserDrawer
        userKey={event.user.key}
        open={showUser}
        setOpen={setShowUser}
      />
    </Box>
  );
}

function PostActions({ event, fetchComments, content }) {
  const [open, setOpen] = useState(false);
  const [replying, setReplying] = useState(false);
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitReply = async () => {
    const data = {
      content_key: event.content_key,
      user_key: currentUser.key,
      type: "comment",
      parent_id: event.id,
      data: {
        text: reply,
      },
    };
    try {
      const endpoint = API_BASE + "/event";
      const response = await axios.post(endpoint, data);
      console.log("Reply:", response.data);
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setReplying(false);
      setReply("");
      setOpen(false);
      fetchComments();
    }
  };

  const handleDelete = async (event) => {
    console.log("Delete:", event);
    const result = confirm("Are you sure you want to remove this comment?");
    if (result) {
      const endpoint = API_BASE + "/comment/delete";
      const response = await axios.post(endpoint, {
        id: event.id,
        user_key: currentUser.key,
      });
      console.log("Delete:", response.data);
      fetchComments();
    }
  };

  const canDelete = () => {
    return (
      event.user_key == currentUser.key || currentUser.key == content.owner.key
    );
  };

  const handleClickReply = () => {
    setOpen(true);
    setReplying(true);
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          id="post-actions"
          style={{ display: "flex", justifyContent: "flex-start", gap: 4 }}
        >
          {!event.parent_id && (
            <Button
              variant="text"
              size="small"
              style={{
                color: "lightgray",
                marginLeft: "-10px",
                fontWeight: 400,
              }}
              sx={{ pl: 0 }}
              onClick={handleClickReply}
            >
              Reply
            </Button>
          )}

          {/* <span style={{ color: "lightgray" }}>|</span> */}
          {canDelete() && (
            <Button
              variant="text"
              size="small"
              style={{
                color: "lightgray",
                fontWeight: 400,
                marginLeft: "-5px",
              }}
              sx={{ pl: 0 }}
              onClick={() => handleDelete(event)}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Container maxWidth="sm" sx={{ pb: 10 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ my: 2, pl: 0 }}
              color="inherit"
            >
              <Cancel />
            </IconButton>
          </Box>

          {replying && (
            <Box
              sx={{
                display: "flex",
                // margin: "20px 0",
                alignItems: "center",
                padding: "20px 0",
              }}
            >
              <Avatar
                src={currentUser.meta.profile}
                sx={{ width: 40, height: 40 }}
              />
              <TextField
                fullWidth
                multiline
                autoComplete="off"
                maxRows={4}
                size="small"
                // placeholder=f"Replying to {event.user.name}..."
                placeholder={`Reply to ${event.user.name}...`}
                variant="outlined"
                value={reply}
                sx={{ mx: 1 }}
                // make the textfield rounded
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                onChange={(e) => setReply(e.target.value)}
                onKeyDown={(e) => {
                  if (e.ctrlKey && e.key === "Enter") {
                    handleSubmitReply(e);
                  }
                }}
              />
              <Box>
                <IconButton
                  size="small"
                  style={{ background: "#9A5AEF" }}
                  onClick={handleSubmitReply}
                  disabled={loading}
                >
                  <ArrowUpward />
                </IconButton>
              </Box>
            </Box>
          )}
        </Container>
      </Drawer>
    </Box>
  );
}
